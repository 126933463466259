<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        รายละเอียดผู้ใช้ <strong>{{currentUser.username}}</strong>
      </h3>
    </header>
    <p>
      <strong>โทเคน:</strong>
      {{currentUser.accessToken.substring(0, 20)}} ... {{currentUser.accessToken.substr(currentUser.accessToken.length - 20)}}
    </p>
    <p>
      <strong>ไอดี:</strong>
      {{currentUser.id}}
    </p>
    <p>
      <strong>อีเมล:</strong>
      {{currentUser.email}}
    </p>
    <p>
      <strong>จังหวัด:</strong>
      {{currentUser.province ? currentUser.province : "ไม่ระบุ"}}
    </p>
    <strong>ระดับผู้ใช้:</strong>
    <ul>
      <li v-for="role in currentUser.roles" :key="role">{{role}}</li>
    </ul>
    <p>
      <strong>การรับรอง:</strong>
      {{ currentUser.approved ? "ผ่านการรับรองแล้ว" : "ยังไม่ผ่านการรับรอง" }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>